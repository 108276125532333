<footer class="bg-[#151515] w-full rounded-lg shadow dark:bg-gray-800 absolute bottom-1 xl:w-full 2xl:w-full">
  <div class="w-full flex items-center justify-between mx-auto max-w-screen-xl p-4 sm:p-0 gap-4 sm:flex sm:items-center sm:justify-between lg:flex lg:items-center lg:justify-between xl:flex xl:items-center xl:justify-between 2xl:flex 2xl:items-center 2xl:justify-between">
    <span class="text-center sm:text-center lg:text-center xl:text-center 2xl:text-center text-[#FFF font-['Inter'] text-sm not-italic font-medium leading-normal">©By End Way. All rights reserved
  </span>
  <ul class="flex flex-wrap mr-4 items-center mt-3 text-[#FFF text-sm font-['Inter'] text-sm not-italic font-medium leading-normal sm:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 xl:mr-4 2xl:mr-4">
      <li>
          <a routerLink="/donate" class="mr-4 hover:underline sm:mr-6 lg:mr-6 xl:mr-6 2xl:mr-6">Помочь проекту</a>
      </li>
      <li>
          <a routerLink="/faq" class="mr-4 hover:underline sm:mr-6 lg:mr-6 xl:mr-6 2xl:mr-6">FAQ</a>
      </li>
      <li>
          <a routerLink="/term" class="mr-4 hover:underline sm:mr-6 lg:mr-6 xl:mr-6 2xl:mr-6">Правила</a>
      </li>
      <li>
          <a href="mailto:admin@endway.org" class="hover:underline">Почта для связи</a>
      </li>
  </ul>
  </div>
</footer>

<!-- <nav class="flex items-center shrink-0 bg-[#151515] absolute bottom-1 w-full" style="
padding: 36px 209px 28px 201px;
border-top: 1px solid var(--stroke, rgba(84, 84, 84, 0.50));">
    <div class="flex flex-wrap justify-between items-center mr-auto ml-auto w-full" 
      style="transition: max-width .2s;">
      <div class="flex justify-content:center items-center gap-12">
      <a href="https://{{ domain }}/donate">
      <p style="color: var(--style, #FFF);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;">Помочь проекту</p>
      </a>
      <a href="https://{{ domain }}/faq">
      <p style="color: var(--style, #FFF);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;">FAQ</p>
      </a>
      <a href="https://{{ domain }}/term">
      <p style="color: var(--style, #FFF);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;">Пользовательское соглашение</p>
      </a>
      </div>
    </div>
</nav> -->
