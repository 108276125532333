<app-header></app-header>
<div class="overflow-y-auto px-2.5 py-2.5 text-justify flex-grow-1">
  <div class="flex w-full">
    <div class="radius-for-skewed mr-auto mt-5 ml-[43em] sm:ml-[3px] sm:w-full md:ml-[3px] lg:ml-auto xl:ml-auto 2xl:ml-auto">
      <div class="flex flex-col justify-center items-center py-12 px-20 gap-4 rounded-e-xl rounded-s-xl shadow-[0px_4px_4px_0px_rgba(0, 0, 0, 0.25)] bg-main border border-solid border-[#aaaaaa]">
        <span class="justify-center:stretch text-[#FFF] drop-shadow-[0px_4px_4px_rgba(0, 0, 0, 0.25)] font-['Roboto'] text-2xl not-italic font-medium leading-normal">Анонимная загрузка файла</span>
        <ngx-dropzone class="flex flex-col gap-4 border border-solid border-[#FFF] rounded-e-lg rounded-s-lg justify-center items-center shrink-0 pl-0 shadow-[0px_4px_4px_0px_rgba(0, 0, 0, 0.25)] bg-main"
        [style]="{
            'width': '337px',
            'border':' 1px solid var(--style, #FFF)',
            'background': '#151515'
          }"
          [multiple]="false"
          (change)="onFileSelected($event)"
        >
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.5714 55.375C11.7665 55.375 6.25 49.9234 6.25 43.1985C6.25 36.4736 11.7665 31.022 18.5714 31.022C19.3883 31.022 20.1866 31.1005 20.959 31.2506M20.959 31.2506C20.3003 29.4937 19.9405 27.5935 19.9405 25.6103C19.9405 16.6438 27.2958 9.375 36.3691 9.375C44.8207 9.375 51.7817 15.6819 52.6959 23.7931M20.959 31.2506C22.5817 31.5654 24.0902 32.195 25.4167 33.0725M41.8454 23.8282C43.558 23.2299 45.4012 22.9044 47.3214 22.9044C49.2036 22.9044 51.0117 23.2172 52.6959 23.7931M52.6959 23.7931C59.1299 25.9932 63.75 32.0332 63.75 39.1396C63.75 46.9222 58.2087 53.426 50.8125 55.0076" stroke="#FACD78" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M35 46.75V64M35 46.75L40.75 52.5M35 46.75L29.25 52.5" stroke="#FACD78" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="mb-2 text-sm text-gray-300">
            <span class="font-semibold">Нажмите, чтобы загрузить или
            перетащите</span>
          </p>
        </ngx-dropzone>
      </div>
      <div
    *ngIf="uploadProgress"
    class="radius-for-skewed ml-auto mt-4 sm:relative sm:w-full sm:ml-[3px] sm:w-full md:ml-[3px] lg:ml-auto"
  >
  <div
            class="bg-[#facd78] text-lg font-medium text-center h-6 mb-4 p-0.5 leading-none rounded-full"
            [ngStyle]="{ width: uploadProgress + '%' }"
          >
            {{ uploadProgress }}%
          </div>
    <div class="flex flex-col justify-center items-center self-stretch gap-4 rounded-e-xl rounded-s-xl border border-solid border-[#aaaaaa] py-12 px-20 bg-main w-full">
      <span style="width: 355px;color: var(--style, #FFF);text-align: center;/* H2 */font-family: Roboto;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;">Загруженные файлы</span>
      <div *ngIf="files" class="flex-col gap-3">
        <div
          *ngFor="let file of files"
        >
          <p style="margin-bottom: 9px; width: 337px; color: #FFF;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 300;line-height: 130%; /* 20.8px */">{{ file.filename }}</p>
          <input type="text" class="inline-flex cursor-text rounded-e-xl rounded-s-xl
          border border-solid border-[#FFF] justify-between
          items-center shrink-0 relative text-center pl-0 h-10 w-full relative
          bg-[#111113] border-[#facd78]
          sm:w-full sm:right-1"
            value="https://{{ domain }}/file/{{ file.fileId }}"
            disabled
          />
        <b style="font-size: 20px; margin-top: 5px;"><fa-icon class="absolute ml-2 sm:ml-[-2rem]" [icon]="faCopy" style="color: #facd78;" (cbOnSuccess)="copy($event)" ngxClipboard [cbContent]="$any(file.couponText)"></fa-icon></b>
        </div>
      </div>
    </div>
</div>
    </div>
  </div>
</div>
<app-footer></app-footer>