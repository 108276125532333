<app-header></app-header>
<div class="container mx-auto flex flex-col items-center m-[10%] min-h-[200px] ">
  <div class="flex flex-col">
    <div class="flex container radius-for-skewed mr-auto mt-5 ml-auto sm:ml-[3px] sm:w-full md:ml-[3px] lg:ml-auto xl:ml-auto 2xl:ml-auto">
      <div class="bg-[#151515] flex-col justify-center self-stretch items-center gap-4 rounded-xl border border-solid border-[#151515] py-12 px-20">
      <div class="flex flex-col gap-3 flex-wrap items-center">
        <span class="w-80 text-[#FFF] text-center font-['Roboto'] text-2xl not-italic font-medium leading-normal">{{ file.originalName }}</span>
        <div class="flex flex-col shrink-0 justify-center items-center gap-2.5 w-80 h-24 rounded-xl border border-solid border-[#FFF]">
<svg xmlns="http://www.w3.org/2000/svg" width="75" height="74" viewBox="0 0 75 74" fill="none">
  <path d="M19 44.7085H43.6667" stroke="#FACD78" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M19 55.5H35.9583" stroke="#FACD78" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M40.5833 7.7085V15.4168C40.5833 22.6843 40.5833 26.318 42.841 28.5758C45.0989 30.8335 48.7326 30.8335 56 30.8335H66.5" stroke="#FACD78" stroke-width="1.5"/>
  <path d="M66.7917 30.8332V30.8332C67.7788 31.8203 68.3333 33.1591 68.3333 34.5551V53.544C68.3333 54.843 68.174 56.1371 67.859 57.3973L67.5848 58.4938C67.0658 60.5698 65.9923 62.4658 64.4792 63.979V63.979C62.966 65.4922 61.07 66.5657 58.9939 67.0847L57.8974 67.3588C56.6372 67.6739 55.3431 67.8332 54.0441 67.8332H26.7083H22.3802C20.1472 67.8332 17.9348 67.4071 15.8615 66.5778L14.5616 66.0578C12.4229 65.2023 10.5606 63.7751 9.17854 61.9323V61.9323C7.54805 59.7584 6.66666 57.1142 6.66666 54.3967V18.9442C6.66666 17.6288 6.87872 16.322 7.2947 15.074V15.074C7.89552 13.2716 8.90776 11.6337 10.2512 10.2902L10.3364 10.2051C11.9703 8.57124 14.0174 7.41214 16.2591 6.85173L17.1025 6.64087C18.3627 6.32581 19.6568 6.1665 20.9558 6.1665H31.3333H40.5833M66.7917 30.8332L40.5833 6.1665M66.7917 30.8332H48.2917V30.8332C46.3174 30.8332 44.4241 30.0489 43.0281 28.6529L42.9929 28.6178C42.4181 28.0429 41.9396 27.3791 41.5761 26.652L41.4969 26.4938C40.8961 25.2921 40.5833 23.9671 40.5833 22.6236V6.1665" stroke="#FACD78" stroke-width="1.5" stroke-linejoin="round"/>
</svg>
        </div>
        
        <a class="flex text-black py-2 px-6 rounded-lg bg-[#facd78] transition-all duration-300 hover:bg-[#302f2f] hover:text-white w-full text-center justify-center items-center"
          href="{{ baseUrl }}/files/{{ file.fileId }}"
          >Скачать {{ formatBytes(file.size) }}</a>
      </div>
      <div class="relative align:center w-max before:absolute before:h-[1px] before:right-[135px] before:top-[10px] before:w-[100px] before:content-[''] before:bg-[#b3bcc5] after:absolute after:h-[1px] after:left-[135px] after:top-[10px] after:w-[100px] after:content-[''] after:bg-[#b3bcc5]" style="padding: 0 16px; margin: 21px 90px auto 109px;;">ПОДЕЛИТЬСЯ</div>
    
    <div *ngIf="file">
      <input type="text" class="inline-flex cursor-text rounded-e-xl rounded-s-xl
      border border-solid border-[#FFF] justify-between
      items-center shrink-0 relative text-center pl-0 h-10 w-full relative
      bg-[#111113] border-[#facd78]
      sm:w-full sm:right-1"
        value="https://{{ domain }}/file/{{ file.fileId }}"
        disabled
      /><b style="font-size: 20px; margin-top: 5px;"><fa-icon class="absolute ml-2 sm:ml-[-2rem]" [icon]="faCopy" style="color: #facd78;" (cbOnSuccess)="copy($event)" ngxClipboard [cbContent]="couponText"></fa-icon></b>
    </div>
    <a
          class="flex items-center justify-center self-stretch text-black py-2.5 px-6 gap-6 rounded-lg bg-[#FFF] font-['Inter'] text-sm not-italic font-medium leading-normal mt-4"
          href="https://{{ domain }}/"
          >Загрузить файл </a>
  </div>
    </div>
  </div>
</div>
<app-footer></app-footer>