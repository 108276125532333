import { Component } from '@angular/core';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faBtc } from '@fortawesome/free-brands-svg-icons';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faUsd } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { ClipboardService } from 'ngx-clipboard';
import { HotToastService } from '@ngneat/hot-toast';

interface IFileResponse {
  code: number;
  data: any;
  msg: string;
}

interface IFiles {
  fileId: string;
  filename: string;
}

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styles: [`
    :host {
      position:fixed;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  `]
})
export class DonateComponent {
  faCopy = faCopy;
  faBtc = faBtc;
  faEthereum = faEthereum;
  faTelegram = faTelegram;
  faUsd = faUsd;
  faHeart = faHeart;

  constructor(private toastService: HotToastService) {}

  ngOnInit() {}

  copy(e: any) {
    if (e.isSuccess) {
      this.toastService.success('Скопировано!', {
        duration: 1500,
      })
    }
  }
}
