import { Component } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  domain = '';


  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: any) {}

  ngOnInit() {
    this.domain = this.document.location.hostname;
  }
}
