import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { TermComponent } from './term/term.component';
import { FaqComponent } from './faq/faq.component';
import { DonateComponent } from './donate/donate.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { FileDragNDropDirective } from './directives/dnd.directive';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FileComponent } from './file/file.component';
import { AuthComponent } from './auth/auth.component';
import { AdminComponent } from './admin/admin.component';
import { StatsComponent } from './stats/stats.component';
import { FilesComponent } from './files-stats/files-stats.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HotToastModule } from '@ngneat/hot-toast';

@NgModule({
  declarations: [
    AppComponent,
    FileDragNDropDirective,
    MainComponent,
    TermComponent,
    FaqComponent,
    DonateComponent,
    HeaderComponent,
    FooterComponent,
    FileComponent,
    AuthComponent,
    AdminComponent,
    StatsComponent,
    FilesComponent,
  ],
  imports: [
    BrowserModule,
    NgxDropzoneModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ClipboardModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    HotToastModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
