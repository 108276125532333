<nav class="flex items-center shrink-0 bg-[#151515] h-16 px-52 2xl:pl-4"
style="border-bottom: 1px solid rgba(84, 84, 84, 0.50);">
    <div class="flex">
      <div class="flex shrink-0 mr-4 gap-10 items-center">
        <a href="https://{{domain}}"><img align="left" class="w-11 h-10"
            src="https://endway.org/styles/endwayui/basic/logo_yellow.png"
            alt="End Way - форум социальной инженерии и программирования"
            srcset="
              https://endway.su/styles/endwayui/basic/logo_yellow.png 2x
            "
        /></a>
      </div>
      <div class="flex justify-center items-center gap-12">
      <a href="https://endway.org">
      <p style="color: var(--style, #FFF);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;">Форум</p>
      </a>
      <a href="https://ewaysu.t.me">
      <p style="color: var(--style, #FFF);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;">EndWay</p>
      </a>
      <a href="https://end_soft.t.me">
      <p style="color: var(--style, #FFF);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;">EndSoft</p>
      </a>
      <a href="https://t.me/+P8m0VQJSp8UzNDUy">
        <p style="color: var(--style, #FFF);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;">EWStudio</p>
        </a>
      </div>
    </div>
    <!-- <div class="flex">
      <a href="mailto:admin@endway.su">
      <p style="color: var(--style, #FFF);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      position: relative;
      float: left;">admin@endway.su</p>
      </a>
    </div> -->
</nav>
