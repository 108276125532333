<app-header></app-header>
<ul>
    <li><a href="/admin/stats">Общая статистика</a></li>
    <li><a href="/admin/files">Список загруженных файлов и Hash</a></li>
  </ul>
  <div class="container mx-auto flex flex-col items-center m-[10%] min-h-[200px]">
    <div class="flex flex-col" style="margin-top: -130px;">
      <h2 style="
    color: var(--style, #FFF);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Roboto;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  padding-bottom: 16px;">Общая статистика</h2>
      <div class="flex container radius-for-skewed mx-auto">
        <div style="padding: 50px 74px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-radius: 10px;
        border: 1px solid var(--stroke, rgba(84, 84, 84, 0.50));
        background: var(--f-2, #151515);">
        <div class="flex flex-col gap-3 flex-wrap items-center">
          <table id="stats" class="stats">
            <thead>
            <tr>
                <th class="thead">Количество загруженных файлов</th>
                <th class="thead">Размер загруженных файлов</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="tdd">{{ stats.filesCount }}</td>
                <td class="tdd">{{ stats.filesSize }} GB</td>
              </tr>
            </tbody>
        </table>
        </div>
    </div>
      </div>
    </div>
  </div>