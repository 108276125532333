<app-header></app-header>
<div class="container mx-auto flex flex-col items-center m-[10%] min-h-[200px]">
  <div class="flex flex-col" style="margin-top: -130px;">
    <h2 style="
  color: var(--style, #FFF);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
padding-bottom: 16px;">Вход в админку</h2>
    <div class="flex container radius-for-skewed mx-auto">
      <div style="padding: 50px 74px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 10px;
      border: 1px solid var(--stroke, rgba(84, 84, 84, 0.50));
      background: var(--f-2, #151515);">
      <div class="flex flex-col gap-3 flex-wrap items-center">
        <div>
          <div class="spacer">
            <input style="cursor: text;
            border-radius: 8px;
            border: 1px solid white;
            display: inline-flex;
            height: 41px;
            padding-left: 0px;
            justify-content: space-between;
            align-items: center;
            flex-shrink: 0;
            width: 132%;
            position: relative;
            right: 2em;
            text-align: center;"
            class="inline-flex bg-[#111113] border-[#facd78] rounded-lg"
              type="text"
              placeholder="Логин"
              [(ngModel)]="username" />
          </div>
          <div class="spacer">
            <input
            style="cursor: text;
            border-radius: 8px;
            border: 1px solid white;
            display: inline-flex;
            height: 41px;
            padding-left: 0px;
            justify-content: space-between;
            align-items: center;
            flex-shrink: 0;
            width: 132%;
            position: relative;
            right: 2em;
            top: 1em;
            text-align: center;"
            class="inline-flex bg-[#111113] border-[#facd78] rounded-lg"
              type="password"
              placeholder="Пароль"
              [(ngModel)]="password"
            />
          </div>
          <div class="spacer">
            <button class="w-full" style="
              display: flex;
              padding: 10px 25px;
              justify-content: center;
              align-items: center;
              gap: 25px;
              align-self: stretch;
              border-radius: 8px;
              background: var(--Style, #FACD78);
              position: relative;
              top: 2em;"
                type="submit" (click)="login()">
              <p style="color: var(--Style, #111113);
              text-align: center;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%; /* 20.8px */">Login</p></button>
          </div>
        </div>
      </div>
  </div>
    </div>
  </div>
</div>
