<app-header></app-header>
<div class="container mx-auto flex flex-col items-center m-[10%]  min-h-[200px] sm:overflow-y-auto px-2.5 py-2.5 flex-grow-1">
  <div class="flex flex-col mr-auto mt-[-130px] ml-auto sm:ml-[3px] sm:w-full md:ml-[3px] sm:mt-[-10px] lg:ml-auto xl:ml-auto 2xl:ml-auto">
    <div class="flex container radius-for-skewed mx-auto">
      <div class="py-12 px-20 flex-col justify-center items-center gap-12 self-stretch rounded-e-xl rounded-s-xl border border-solid border-[#aaaaaa] bg-[#151515]">
      <div class="flex flex-col gap-3 flex-wrap items-center">
        <h2 class="text-[#fff]  drop-shadow-[0px_4px_4px_rgba(0, 0, 0, 0.25)] text-center pb-4 font-['Roboto'] text-4xl sm:text-xl not-italic font-medium leading-normal">Пожертвование</h2>
        <ol class="list-disc">
          <li>Если вам понравился наш файлообменник и вы хотите его поддержать.<br>Вы можете это сделать либо через наш <b><a style="color: #facd78;" href="https://endway.su/donate/">форум</a></b></li>
          <li class="pb-[15px]">Вы так же можете использовать эти реквизиты:
            <ul >
                <li><fa-icon [icon]="faUsd" style="color:#50af95"></fa-icon> USDT TRC-20 <b><fa-icon [icon]="faCopy" style="color: #facd78;" (cbOnSuccess)="copy($event)" ngxClipboard [cbContent]="'TLEmvQfMP4kuj2G9cMtBqpkd2PqweR8Lue'"></fa-icon></b></li>
                <li><fa-icon [icon]="faBtc" style="color:#F7931A"></fa-icon> BTC <b><fa-icon [icon]="faCopy" style="color: #facd78;" (cbOnSuccess)="copy($event)" ngxClipboard [cbContent]="'bc1qss2uh9dupvm6s2shmxj5arlwdvy33ak3pjrdkh'"></fa-icon></b></li>
                <li><fa-icon [icon]="faEthereum"></fa-icon> ETH <b><fa-icon [icon]="faCopy" style="color: #facd78;" (cbOnSuccess)="copy($event)" ngxClipboard [cbContent]="'0xB467819f3527f73f6C94DBAAd59eD551e21ee93A'"></fa-icon></b></li>
                <li><fa-icon [icon]="faTelegram" style="color:#0098EA"></fa-icon> TON <b><fa-icon [icon]="faCopy" style="color: #facd78;" (cbOnSuccess)="copy($event)" ngxClipboard [cbContent]="'EQAnPTMO-QYwxMgJTc9QhvoRFfyjw3vS0H9F5phzN-JT7lTt'"></fa-icon></b></li>
            </ul>
        </li>
        </ol>
        <b><p>Благодарим за то, что поддерживаете нас! <fa-icon [icon]="faHeart" style="color:#facd78"></fa-icon></p></b>
      </div>
    </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>