import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
})
export class AuthComponent implements OnInit {
	username = ''
	password = ''

	constructor(private authService: AuthService) { }

	ngOnInit() { }
	login() {
		this.authService.auth(this.username, this.password);
	}

}
