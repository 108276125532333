import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API } from '../config/api';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private readonly http: HttpClient, private readonly router: Router) { }

  public get IsLoggedIn(): boolean {
    return (localStorage.getItem('access_token') !== null);
  }

  public auth(username: string, password: string) {
    this.http.post(`${API.base}${API.auth}`, { username: username, password: password })
      .subscribe((resp: any) => {
        this.router.navigate(['admin']);
        localStorage.setItem('access_token', resp.access_token);
      });
  }
}
