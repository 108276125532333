import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Input } from '@angular/core';
import { Subscription, finalize } from 'rxjs';
import { API } from '../config/api';
import { ClipboardService } from 'ngx-clipboard'
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { HotToastService } from '@ngneat/hot-toast';

interface IFileResponse {
  code: number;
  data: any;
  msg: string;
}

interface IFiles {
  fileId: string;
  filename: string;
  couponText: string;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: [`
    :host {
      position:fixed;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  `]
})
export class MainComponent {
  domain = '';
  @Input() name: string;
  files: IFiles[] = [];
  faCopy = faCopy;
  uploadProgress: number;
  uploadSub: Subscription;

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: any, private toastService: HotToastService, private _clipboardService: ClipboardService) { }

  ngOnInit() {
    this.domain = this.document.location.hostname;
  }

  contenCopied = false;

  onFileSelected(event: any) {
    const file: File = event.addedFiles[0];

    if (file) {
      if (file.size > 150000000) {
        return this.toastService.error('Размер файла не должен быть более 150 МБ', {
          duration: 1500,
        })
      }
      
      const formData = new FormData();
      formData.append('file', file);

      const upload$ = this.http
        .post(`${API.base}${API.add}`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .pipe(finalize(() => setTimeout(() => this.reset(), 1000)));

      this.uploadSub = upload$.subscribe((event) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
        if (event.type == HttpEventType.Response) {
          const body = event.body as IFileResponse;
          let couponText: string;
          couponText = 'https://' + this.document.location.hostname + '/file/' + body.data.fileId;
          this.files.push({
            fileId: body.data.fileId,
            filename: body.data.originalName,
            couponText: couponText,
          });
        }
      });
    }
  }

  copy(e: any) {
    if (e.isSuccess) {
      this.toastService.success('Скопировано!', {
        duration: 1500,
      })
    }
  }

  error(e: any) {
    if (e.isError) {
      this.toastService.error('Размер файла не должен быть более 150 МБ', {
        duration: 1500,
      })
    }
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadSub = null;
  }
}
