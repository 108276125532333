<ul>
    <li><a href="/admin/stats">Общая статистика</a></li>
    <li><a href="/admin/files">Список загруженных файлов и Hash</a></li>
  </ul>
<!-- <table id="stats" class="stats">
    <thead>
    <tr>
        <th class="thead">Количество загруженных файлов</th>
        <th class="thead">Размер загруженных файлов</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tdd">{{ stats.filesCount }}</td>
        <td class="tdd">{{ stats.filesSize }} GB</td>
      </tr>
    </tbody>
</table>

<table id="hash" class="files">
    <thead>
    <tr>
        <th class="thead">Имя</th>
        <th class="thead">Hash</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of stats.files">
        <td class="tdd">{{ file.originalName }}</td>
        <td class="tdd">{{ file.filename }}</td>
      </tr>
    </tbody>
</table> -->