import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from '../config/api';
import { Subscription, finalize } from 'rxjs';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { HotToastService } from '@ngneat/hot-toast';

export interface IFile {
  id: number;
  fileId: string;
  filename: string;
  originalName: string;
  size: number;
  mimetype: string;
  downloads: number;
}

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styles: [`
    :host {
      position:fixed;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  `]
})
export class FileComponent implements OnInit {
  domain = '';
  input: string;
  faCopy = faCopy;
  baseUrl: string;
  fileId: string;
  file: IFile;
  getFileSub: Subscription;

  constructor(private route: ActivatedRoute, private readonly router: Router, private http: HttpClient, @Inject(DOCUMENT) private document: any, private toastService: HotToastService) {}

  ngOnInit() {
    this.fileId = this.route.snapshot.params['id'];
    this.baseUrl = API.base;
    this.domain = this.document.location.hostname;
    const getFile$ = this.http
      .get(`${API.base}${API.get.replace(':id', this.fileId)}`, {})
      .pipe(finalize(() => this.reset()));
    this.getFileSub = getFile$.subscribe({
      next: (data) => {
        let body = data as any;
        if (body.data === null) this.router.navigate(['/'])
        this.file = body.data;
      },
    });

  }

  couponText = 'https://' + this.document.location.hostname + '/file/' + this.route.snapshot.params['id'];

  contenCopied = false;

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  copy(e: any) {
    if (e.isSuccess) {
      this.toastService.success('Скопировано!', {
        duration: 1500,
      })
    }
  }

  reset() {
    this.getFileSub.unsubscribe();
  }
}
