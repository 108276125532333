<app-header></app-header>
<div class="container mx-auto flex flex-col items-center m-[10%]  min-h-[200px] sm:overflow-y-auto px-2.5 py-2.5 flex-grow-1">
  <div class="flex flex-col mr-auto mt-[-130px] ml-auto sm:ml-[3px] sm:w-full md:ml-[3px] sm:mt-[-10px] lg:ml-auto xl:ml-auto 2xl:ml-auto">
    <div class="flex container radius-for-skewed mx-auto">
      <div class="py-12 px-20 flex-col justify-center items-center gap-12 self-stretch rounded-e-xl rounded-s-xl border border-solid border-[#aaaaaa] bg-[#151515]">
      <div class="flex flex-col gap-3 flex-wrap items-center">
        <h2 class="text-[#fff]  drop-shadow-[0px_4px_4px_rgba(0, 0, 0, 0.25)] text-center pb-4 font-['Roboto'] text-4xl sm:text-xl not-italic font-medium leading-normal">Часто задаваемые вопросы</h2>
        <ol class="list-disc">
          <li>Q: Файлообменник ewfiles.su бесплатный?</li>
          <ul class="pb-[15px]">
            <li>Да, загрузка и скачивание на 100% бесплатны для всех пользователей.</li>
        </ul>
        <li>Q: Будут ли мои файлы удалены?</li>
        <ul class="pb-[15px]">
          <li>Файлы хранятся неограниченный период. </li>
      </ul>
      <li>Q: Сколько файлов я могу загружать?</li>
      <ul class="pb-[15px]">
          <li>Вы можете загружать столько файлов, сколько хотите, при условии, что каждый из них соответствует условиям и максимальному размеру загружаемого файла.</li>
      </ul>
      <li>Q: Какие типы файлов разрешены для загрузки?</li>
      <ul class="pb-[15px]">
          <li>Вы можете загружать следующие типы файлов: Любые.</li>
      </ul>
      <li>Q: Есть ли какие-либо ограничения на размер загружаемых файлов?</li>
      <ul class="pb-[15px]">
          <li>Каждый загружаемый файл не должен превышать 150 MB.</li>
      </ul>
      <li>Q: Файлообменник анонимен?</li>
      <ul class="pb-[15px]">
          <li>Да, мы не собираем IP-адреса. Вы можете анонимно отправлять файлы, но помните, что есть <b><a style="color: #facd78;" href="https://{{domain}}/term">правила.</a></b></li>
      </ul>
      <li>Q: У вас есть API?</li>
      <ul class="pb-[15px]">
          <li>Нет, но в следующем обновлении обязательно добавим.</li>
      </ul>
      </ol>
      </div>
  </div>
    </div>
  </div>
</div>
<app-footer></app-footer>