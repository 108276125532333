import { Component } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [`
    :host {
      height: 32px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
    }
  `]
})

export class FooterComponent {
  domain = '';


  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: any) {}

  ngOnInit() {
    this.domain = this.document.location.hostname;
  }

}
