<app-header></app-header>
<div class="container mx-auto flex flex-col items-center m-[10%] sm:mt-4 min-h-[200px] sm:overflow-y-auto px-2.5 py-2.5 text-justify flex-grow-1">
  <div class="flex flex-col mr-auto mt-[-130px] ml-auto sm:ml-[3px] sm:w-full md:ml-[3px] sm:mt-[-10px] lg:ml-auto xl:ml-auto 2xl:ml-auto">
    <div class="flex container radius-for-skewed mx-auto">
      <div class="py-12 px-20 flex-col justify-center items-center gap-12 self-stretch rounded-e-xl rounded-s-xl border border-solid border-[#aaaaaa] bg-[#151515]">
      <div class="flex flex-col gap-3 flex-wrap items-center">
        <h2 class="text-[#fff]  drop-shadow-[0px_4px_4px_rgba(0, 0, 0, 0.25)] text-center pb-4 font-['Roboto'] text-4xl sm:text-xl not-italic font-medium leading-normal">Пользовательское соглашение</h2>
        <ol class="list-decimal">
          <li class="pb-[15px]">Ресурс ewfiles.su (Далее – Ресурс) предназначен для обмена и хранения файлов.</li>
          <li class="pb-[15px]">Данное Пользовательское соглашение вступает в силу с момента начала пользования Ресурсом.</li>
          <li class="pb-[15px]">При пользовании Ресурсом запрещается размещать:                                
              <ul>
                  <li>Материалы противоречащие законодательству вашей страны.</li>
                  <li>Объекты интеллектуальной собственности, копирование которых запрещено владельцами оригинала.</li>
                  <li>Вредоносные программы.</li>
                  <li>НЕ ЛЕЙ ЦП ДОЛБОЕБ, ЕСЛИ ТЫ ЗАЛЬЕШЬ МЫ ТЕБЯ СОЛЬЕМ НАХУЙ КЛОУН ЕБУЧИЙ.</li>
              </ul>
          </li>
          <li class="pb-[15px]">Загруженные материалы, нарушающие п.3 настоящего соглашения  будут удаляться с Ресурса при обнаружении.</li>
          <li class="pb-[15px]">На сервисе не гарантируется сохранность файлов.</li>
          <li class="pb-[15px]">Ни при каких обстоятельствах Администрация Ресурса не несет ответственности за материалы загружаемые, отправляемые, полученные Пользователями посредством Ресурса.</li>
          <li class="pb-[15px]">Ни при каких обстоятельствах Администрация Ресурса не несет ответственности за всевозможные упущенные выгоды и за любые убытки по любым причинам.</li>
      </ol>
    </div>
  </div>
    </div>
  </div>
</div>
<app-footer></app-footer>