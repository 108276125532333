import { Routes } from '@angular/router';
import { MainComponent } from '../main/main.component';
import { TermComponent } from '../term/term.component';
import { FaqComponent } from '../faq/faq.component';
import { DonateComponent } from '../donate/donate.component';
import { FileComponent } from '../file/file.component';
import { AuthComponent } from '../auth/auth.component';
import { AdminComponent } from '../admin/admin.component';
import { StatsComponent } from '../stats/stats.component';
import { FilesComponent } from '../files-stats/files-stats.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: MainComponent,
    children: [],
  },
  {
    path: 'file/:id',
    component: FileComponent,
    children: [],
  },
  {
    path: 'term',
    component: TermComponent,
    children: [],
  },
  {
    path: 'faq',
    component: FaqComponent,
    children: [],
  },
  {
    path: 'donate',
    component: DonateComponent,
    children: [],
  },
  {
    path: 'admin/auth',
    component: AuthComponent,
    children: [],
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [],
  },
  {
    path: 'admin/stats',
    component: StatsComponent,
    children: [],
  },
  {
    path: 'admin/files',
    component: FilesComponent,
    children: [],
  }
];
