import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { API } from '../config/api';
import { Subscription, finalize } from 'rxjs';
import { IFile } from '../file/file.component';

interface IStats {
  filesCount: number,
  filesSize: string,
  fileId: string,
  originalName: string,
  filename: string,
  files: IFile[],
}

@Component({
  selector: 'app-files-stats',
  templateUrl: './files-stats.component.html',
  styleUrls: ['./files-stats.component.css']
})
export class FilesComponent implements OnInit {
  stats: IStats;
  getStatsSub: Subscription;

  constructor(private readonly authService: AuthService, private readonly router: Router, private readonly http: HttpClient) { }

  ngOnInit() {
    if (!this.authService.IsLoggedIn) this.router.navigate(['admin/auth'])

    const getStats$ = this.http
      .get(`${API.base}${API.stats}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .pipe(finalize(() => this.reset()));
    this.getStatsSub = getStats$.subscribe({
      next: (data) => {
        let body = data as any;
        this.stats = body.data;
      },
    });
  }

  reset() {
    this.getStatsSub.unsubscribe();
  }
}
