import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Subscription, finalize } from 'rxjs';
import { API } from '../config/api';

interface IFileResponse {
  code: number;
  data: any;
  msg: string;
}

interface IFiles {
  fileId: string;
  filename: string;
}

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styles: [`
    :host {
      position:fixed;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  `]
})
export class TermComponent {
  domain = '';
  files: IFiles[] = [];
  uploadProgress: number;
  uploadSub: Subscription;

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: any) {}

  ngOnInit() {
    this.domain = this.document.location.hostname;
  }

  onFileSelected(event) {
    const file: File = event.addedFiles[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const upload$ = this.http
        .post(`${API.base}${API.add}`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .pipe(finalize(() => setTimeout(() => this.reset(), 1000)));

      this.uploadSub = upload$.subscribe((event) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
        if (event.type == HttpEventType.Response) {
          const body = event.body as IFileResponse;
          this.files.push({
            fileId: body.data.fileId,
            filename: body.data.originalName,
          });
        }
      });
    }
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
}
